export const Track: any = {
  geocode: "",
  lat: "",
  lng: "",
  accid: "",
  deviceid: "",
  vehnum: "",
  imei: "",
  alt: "",
  disha: "",
  tamp: "",
  speed: "",
  ign: "",
  fuel: "",
  load: "",
  imb: "",
  ac: "",
  battery: "",
  lock: "",
  rfid: "",
  reason: "",
  pktype: "",
  gps: "",
  satellites: "",
  orgts: "",
  cts: "",
  powsts: "",
  devicetypeid: "",
  address: "",
  accuracy: "",
  orgmillis: "",
  unlockstatus: "",
  batchg: "",
};

export const Trip: any = {
  enddate: "",
  tripId: "",
  drivername: "",
  permitendDate: "",
  transitType: "",
  sleeptime: "",
  permitstartDate: "",
  speed: "",
  permitno: "",
  via: "",
  consigneeename: "",
  vehnum: "",
  material: "",
  qty: "",
  sms: "",
  imei: "",
  driverphone: "",
  vehtype: "",
  consignorname: "",
  email: "",
  name: "",
  lat: "",
  lng: "",
  mode: "",
  amount: "",
  comments: "",
  transid: "",
  payment: "",
  paymentdetails: "",
  time: "",
  refid: "",
};

export const collectionOptions = [
  "ADN_RAWDATA",
  "ATM_RAWDATA",
  "ATM_TRACKDATA",
  "BHEX_RAWDATA",
  "BHEX_TRACKDATA",
  "BSFC_ALERTDATA",
  "BSFC_RAWDATA",
  "BSFC_RESPDATA",
  "BSFC_TRACKDATA",
  "BSNL_RAWDATA",
  "COMMON_CONFIG",
  "DLVR_RAWDATA",
  "DLVR_TRACKDATA",
  "ELK_ALERTDATA",
  "ELK_RAWDATA",
  "ELK_RESPDATA",
  "ELK_TRACKDATA",
  "EX_ALERTDATA",
  "EX_RAWDATA",
  "EX_RESPDATA",
  "EX_TRACKDATA",
  "GEO_HUBS",
  "GSCS_ALERTDATA",
  "GSCS_RAWDATA",
  "GSCS_RESPDATA",
  "GSCS_TRACKDATA",
  "IMZ_ALERTDATA",
  "IMZ_PUSH_TO_CLIENT",
  "IMZ_RAWDATA",
  "IMZ_RESPDATA",
  "IMZ_TRACKDATA",
  "IMZ_TRIPS",
  "LOCATIONS",
  "LORA_ALERTDATA",
  "LORA_TRACKDATA",
  "MATERIALS",
  "NTA_ALERTDATA",
  "NTA_RAWDATA",
  "NTA_RESPDATA",
  "NTA_TRACKDATA",
  "PRAJ_ALERTDATA",
  "PRAJ_GEN_REPORT",
  "PRAJ_NONFUNC_REPORT",
  "PRAJ_RAWDATA",
  "PRAJ_REPORT",
  "PRAJ_TRACKDATA",
  "PUSH_TO_CLIENT",
  "ROUTE",
  "ROUTE_GEO",
  "TM_ALERTDATA",
  "TM_RAWDATA",
  "TM_TRACKDATA",
  "TRIPS",
  "TST_RAWDATA",
  "TST_TRACKDATA",
  "UPEX_ALERTDATA",
  "UPEX_RAWDATA",
  "UPEX_RESPDATA",
  "UPEX_TRACKDATA",
  "USER_RIGHTS",
  "WBEX_ALERTDATA",
  "WBEX_RAWDATA",
  "_RAWDATA",
  "ecom_tracks",
  "loadcalibration",
  "stops",
  "trips",
];
