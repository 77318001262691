import HereMapCluster from "./HereMapCluster";

const MapView = () => {
  return (
    <div>
      <HereMapCluster />
    </div>
  );
};

export default MapView;
